const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",
  DANG_NHAP: "/dang-nhap",
  DANG_KY: "/dang-ky",
  DOI_MAT_KHAU: "/doi-mat-khau",
  TRA_CUU_TIM_KIEM: "/tra-cuu-tim-kiem",
  TRA_CUU_HO_SO: "/tra-cuu-ho-so",
  KHAI_THAC_HO_SO: "/khai-thac-ho-so",

  //ANONYMOUS
  LIEN_HE: "/lien-he",
  TIM_KIEM: "/tim-kiem",

  // ADMIN
  YEU_CAU_SDDL: "/yeu-cau-sddl",
  DANH_SACH_BAI_VIET: "/danh-sach-bai-viet",
  DANH_MUC_THE: "/danh-muc-the",
  DANG_BAI: "/dang-bai",
  QUAN_LY_PHAN_QUYEN: "/quan-ly-phan-quyen",
  QUAN_LY_DON_VI: "/quan-ly-don-vi",
  DANH_DACH_NGUOI_DUNG: "/danh-sach-nguoi-dung",
  QUAN_LY_CHUC_VU: "/quan-ly-chuc-vu",
  QUAN_LY_DU_LIEU: "/quan-ly-du-lieu",
  THEM_MOI_TAI_LIEU: "/them-moi-tai-lieu",
  CAP_NHAT_TAI_LIEU: "/cap-nhat-tai-lieu",
  XEM_THONG_TIN_TAI_LIEU: "/Xem-thong-tin-tai-lieu",
  DANH_SACH_HO_SO: "/danh-sach-ho-so",
  QUAN_LY_TAI_LIEU_DINH_KEM: "/quan-ly-tai-lieu-dinh-kem",
  THEM_TAI_LIEU_GIAY: "/quan-ly-tai-lieu-dinh-kem/them-tai-lieu-giay",
  CHINH_SUA_TAI_LIEU_GIAY: "/quan-ly-tai-lieu-dinh-kem/chinh-sua-tai-lieu-giay",
  QUAN_LY_TAI_LIEU_ANH_DINH_KEM: "/quan-ly-tai-lieu-anh-dinh-kem",
  Danh_MUC_HE_THONG: "/danh-muc-he-thong",
  CAU_HINH_CAY_DANH_MUC: "/danh-muc-ho-so",
  DANH_MUC_HE_THONG: "/danh-muc-he-thong",
  THEM_MOI_HO_SO: "/them-ho-so",
  SUA_HO_SO: "/chinh-sua-ho-so",
  BAO_CAO_THONG_KE: "/bao-cao-thong-ke",
  THONG_KE_CHUNG: "/thong-ke-chung",
  THONG_KE_HO_SO_THEO_DVHC: "/thong-ke-ho-so-theo-dvhc",
  THONG_KE_MUON_TRA: "/thong-ke-muon-tra",
  DON_DANG_KY: "/don-dang-ky",
  CAU_HINH_KET_NOI: "/cau-hinh-ket-noi",
  NHAT_KY_HE_THONG: "/nhat-ky-he-thong",
  SAO_LUU_KHOI_PHUC: "/sao-luu-phuc-hoi",
}
export default ROUTER
