import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
// ANONYMOUS
const LoginGoogle = React.lazy(() => import("src/pages/LoginGoogle"))
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const RegisterPage = React.lazy(() => import("src/pages/ANONYMOUS/Register"))
const SearchPage = React.lazy(() => import("src/pages/ANONYMOUS/Search"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() => import("src/pages/USER/ChangePassword"))
const ProfileUsing = React.lazy(() => import("src/pages/USER/ProfileUsing"))

// ADMIN
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const Role = React.lazy(() => import("src/pages/ADMIN/Role"))
const PostManager = React.lazy(() => import("src/pages/ADMIN/PostManager"))
const Tags = React.lazy(() => import("src/pages/ADMIN/Tags"))
const CategoryManager = React.lazy(() =>
  import("src/pages/ADMIN/CategoryManager/index"),
)
const UnitManager = React.lazy(() => import("src/pages/ADMIN/UnitManager"))
const UserManager = React.lazy(() => import("src/pages/ADMIN/UserManager"))
const PositionManager = React.lazy(() =>
  import("src/pages/ADMIN/PositionManager"),
)
const DataManagement = React.lazy(() =>
  import("src/pages/ADMIN/DataManagement"),
)
const SystemCatePage = React.lazy(() => import("src/pages/ADMIN/SystemCate"))
const ConnectConfig = React.lazy(() =>
  import("src/pages/ADMIN/SystemConfig/ConnectConfig"),
)
const SystemHistory = React.lazy(() =>
  import("src/pages/ADMIN/SystemConfig/SystemHistory"),
)
const SystemBackup = React.lazy(() =>
  import("src/pages/ADMIN/SystemConfig/SystemBackup"),
)
const ListProfile = React.lazy(() => import("src/pages/ADMIN/ListProfile"))
const InsertListProfile = React.lazy(() =>
  import("src/pages/ADMIN/ListProfile/Components/InsertListProfile"),
)
const RequestSDDL = React.lazy(() => import("src/pages/ADMIN/RequestSDDL.js"))
const StatisticalGeneral = React.lazy(() =>
  import("src/pages/ADMIN/StatisticalGeneral"),
)
const StatisticalLoanPayment = React.lazy(() =>
  import("src/pages/ADMIN/StatisticalLoanPayment"),
)
const StatisticByUnit = React.lazy(() =>
  import("src/pages/ADMIN/StatisticByUnit"),
)

const AttachedDocumentManagement = React.lazy(() =>
  import("src/pages/ADMIN/AttachedDocumentManagement/AttchedDocumentManament"),
)
const AddDoc = React.lazy(() =>
  import("src/pages/ADMIN/AttachedDocumentManagement/AddDoc"),
)
const EditDoc = React.lazy(() =>
  import("src/pages/ADMIN/AttachedDocumentManagement/EditDoc"),
)
const RegistrationForm = React.lazy(() =>
  import("src/pages/ADMIN/RegistrationForm"),
)
const BasicInformation = React.lazy(() =>
  import("src/pages/ADMIN/BasicInformation"),
)
const ViewDocument = React.lazy(() => import("src/pages/ADMIN/ViewDocument"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <Spin />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  {
    path: ROUTER.LOGIN_GOOGLE,
    element: (
      <LazyLoadingComponent>
        <LoginGoogle />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DANH_SACH_BAI_VIET,
        element: (
          <LazyLoadingComponent>
            <PostManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_DU_LIEU,
        element: (
          <LazyLoadingComponent>
            <DataManagement />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THEM_MOI_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <BasicInformation />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CAP_NHAT_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <BasicInformation />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.XEM_THONG_TIN_TAI_LIEU,
        element: (
          <LazyLoadingComponent>
            <ViewDocument />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_MUC_THE,
        element: (
          <LazyLoadingComponent>
            <Tags />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.QUAN_LY_PHAN_QUYEN,
        element: (
          <LazyLoadingComponent>
            <Role />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_DON_VI,
        element: (
          <LazyLoadingComponent>
            <UnitManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_DACH_NGUOI_DUNG,
        element: (
          <LazyLoadingComponent>
            <UserManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_CHUC_VU,
        element: (
          <LazyLoadingComponent>
            <PositionManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_HO_SO,
        element: (
          <LazyLoadingComponent>
            <ListProfile />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_TAI_LIEU_DINH_KEM,
        element: (
          <LazyLoadingComponent>
            <AttachedDocumentManagement isFolder={true} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_TAI_LIEU_ANH_DINH_KEM,
        element: (
          <LazyLoadingComponent>
            <AttachedDocumentManagement isFolder={false} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THEM_TAI_LIEU_GIAY,
        element: (
          <LazyLoadingComponent>
            <AddDoc />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHINH_SUA_TAI_LIEU_GIAY,
        element: (
          <LazyLoadingComponent>
            <EditDoc />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THEM_MOI_HO_SO,
        element: (
          <LazyLoadingComponent>
            <InsertListProfile />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SUA_HO_SO,
        element: (
          <LazyLoadingComponent>
            <InsertListProfile />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CAU_HINH_CAY_DANH_MUC,
        element: (
          <LazyLoadingComponent>
            <CategoryManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_MUC_HE_THONG,
        element: (
          <LazyLoadingComponent>
            <SystemCatePage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CAU_HINH_KET_NOI,
        element: (
          <LazyLoadingComponent>
            <ConnectConfig />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAT_KY_HE_THONG,
        element: (
          <LazyLoadingComponent>
            <SystemHistory />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SAO_LUU_KHOI_PHUC,
        element: (
          <LazyLoadingComponent>
            <SystemBackup />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.YEU_CAU_SDDL,
        element: (
          <LazyLoadingComponent>
            <RequestSDDL />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_KE_CHUNG,
        element: (
          <LazyLoadingComponent>
            <StatisticalGeneral />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_KE_MUON_TRA,
        element: (
          <LazyLoadingComponent>
            <StatisticalLoanPayment />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_KE_HO_SO_THEO_DVHC,
        element: (
          <LazyLoadingComponent>
            <StatisticByUnit />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DON_DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegistrationForm />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.KHAI_THAC_HO_SO,
        element: (
          <LazyLoadingComponent>
            <ProfileUsing />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TRA_CUU_HO_SO,
        element: (
          <LazyLoadingComponent>
            <SearchPage />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegisterPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TRA_CUU_TIM_KIEM,
        element: (
          <LazyLoadingComponent>
            <SearchPage />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
